<template>
  <v-footer dark padless>
    <v-card flat tile class="black white--text text-center">
      <v-card-text>
        <v-btn
          v-for="(icon, i) in icons"
          :key="i"
          class="mx-4 white--text"
          :href="icon.link"
          target="_blank"
          icon
        >
          <v-icon size="24px">{{ icon.text }}</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        "...Podemos codificar un mundo y una sociedad nueva, solo debemos dominar el lenguaje y las herramientas, vida y conocimiento..."
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>CreativDjango</strong>-SACB
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style scoped>
.v-card {
  width: 100%;
}
</style>

<script>
export default {
  data: () => ({
    icons: [
      {
        text: "mdi-facebook",
        link: "https://www.facebook.com/profile.php?id=100087443119235",
      },
      {
        text: "mdi-twitter",
        link: "https://twitter.com/creativdjango",
      },
      {
        text: "mdi-github",
        link: "https://github.com/creativdjangocode",
      },
      {
        text: "mdi-instagram",
        link: "https://www.instagram.com/creativdjango/",
      },
      {
        text: "mdi-whatsapp",
        link: "https://api.whatsapp.com/send?phone=+584143012888&text=%20hola%20buen%20d%C3%ADa,%20estoy%20interesado%20en%20uno%20de%20sus%20productos%20de%20software,%20puede%20comunicarse%20conmigo?",
      },
    ],
  }),
};
</script>
