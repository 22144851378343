import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC1-18f2mN9PiD_BLv4E1p-6h9Xml2awnw",
  authDomain: "creativdjangocode.firebaseapp.com",
  databaseURL: "https://creativdjangocode-default-rtdb.firebaseio.com",
  projectId: "creativdjangocode",
  storageBucket: "creativdjangocode.appspot.com",
  messagingSenderId: "40115234944",
  appId: "1:40115234944:web:9dd2b66a1d3b8908c65e65",
  measurementId: "G-3RQ20X82BC"
};


const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);