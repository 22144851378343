<template>
  <section class="pb-8" id="contact">
    <v-container fluid>
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row justify="center">
            <v-col cols="12" sm="5">
              <h1 class="font-weight-light display-1">Contacte con Nosotros.</h1>
              <h3 class="font-weight-light mt-3">
                Para brindarle una atencion mas personalizada, por favor llene este formulario, en el cual es necesario
                que deje algun metodo por el cual el personal de ventas pueda comunicarse con usted en breve, tambien
                puede usar el correo o el numero anexo a este texto.
              </h3>
              <h3 class="font-weight-light mt-3">
                Esperamos poder satisfacer todas sus necesidades.
              </h3>
              <h3 class="font-weight-light mt-3">
                Telefono: +58 (414) 301-2888
              </h3>
              <h3 class="font-weight-light">
                Email: empresarial@creativdjango.com
              </h3>
            </v-col>
            <v-col cols="12" sm="7">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field v-model="nombre" :rules="nameRules" label="Nombres" required></v-text-field>

                <v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>

                <v-textarea v-model="mensaje" :rules="textAreaRules" label="Mensaje" required />

                <v-btn :disabled="!valid" color="black" :dark="valid" rounded block class="mt-3"
                  @click="submit(465768, 'absclkj', 'asjdhfkadshk', 'akjsdlkfajlsjgyiyg')">
                  Enviar
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves text-white">
      <v-img src="~@/assets/img/borderWavesBlue.svg" />
    </div>
    <v-snackbar v-model="snackbar.enabled" timeout="3000" shaped center top :color="snackbar.color">
      {{ snackbar.text }}
      <!-- 
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar.enabled = false"
        >
          X
        </v-btn>
      </template> -->
    </v-snackbar>
  </section>
</template>

<style scoped>
#contact {
  background-color: #f4f7f5;
}

.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}
</style>

<script>

import { collection, getDocs, addDoc } from "firebase/firestore";
import { db } from "../firebase"

export default {
  data: () => ({
    valid: true,
    nombre: "",
    nameRules: [
      (v) => !!v || "Este campo es Obligatorio",
      (v) => (v && v.length >= 6) || "Debe tener mas de 6 caracteres",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "Este campo es Obligatorio",
      (v) => /.+@.+\..+/.test(v) || "el E-mail debe ser válido",
    ],
    mensaje: "",
    textAreaRules: [
      (v) => !!v || "Este campo es Obligatorio",
      (v) => (v && v.length >= 10) || "Mínimo de 10 caracteres",
    ],
    lazy: false,
    snackbar: {
      enabled: false,
      text: '',
      color: ''
    }
  }),
  async created() {

  },
  methods: {
    async submit() {
      try {
        const docRef = await addDoc(collection(db, "mensajes"), {
          nombre: this.nombre,
          email: this.email,
          mensaje: this.mensaje
        });
        this.nombre = '';
        this.email = '';
        this.mensaje = '';
        this.$refs.form.reset()
        this.snackbar.text = "Mensaje ha sido enviado con exito"
        this.snackbar.color = "grey darken-4"
        this.snackbar.enabled = true
        // console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
        this.snackbar.text = "Error al enviar el mensaje, intnte nuevamente"
        this.snackbar.color = "danger"
        this.snackbar.enabled = true
      }
    }
  }
};
</script>
