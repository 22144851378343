<template>
  <section id="hero">
    <v-parallax  src="https://i.imgur.com/Uwwy2BB.jpg" height="750">​⁡
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="center">


            
            
            <v-col cols="12" md="6" xl="8" class="">
              <h1 class="tit">Creativ<span class="tit trans"><strong>Django</strong></span></h1>
              <h2 class="font-weight-light tit2">
                Servicios de diseño y asesoria <br />
                para Soluciones Web Empresariales y Personales <br />
                haz que tu marca sea visible en el Mundo!
              </h2>
              <v-btn
                rounded
                outlined
                large
                dark
                @click="$vuetify.goTo('#features')"
                class="mt-5 btn1 hidden-sm-and-down"
              >
                Nuestra filosofia...
                <v-icon class="ml-2">mdi-arrow-down</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" xl="4" class="compu">
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="svg-border-waves text-white">
        <v-img src="@/assets/img/borderWaves.svg" />
      </div>
    </v-parallax>
    <v-container fluid id="features" class="mt-2">
      <v-row align="center" justify="center">
        <v-col cols="10">
          <v-row align="center" justify="space-around">
            <v-col
              cols="12"
              sm="4"
              class="text-center"
              v-for="(feature, i) in features"
              :key="i"
            >
              <v-hover v-slot:default="{ hover }">
                <v-card
                  class="card"
                  shaped
                  :elevation="hover ? 10 : 4"
                  :class="{ up: hover }"
                >
                  <v-img
                    :src="feature.img"
                    max-width="100px"
                    class="d-block ml-auto mr-auto"
                    :class="{ 'zoom-efect': hover }"
                  ></v-img>
                  <h1 class="font-weight-regular">{{ feature.title }}</h1>
                  <h4 class="font-weight-regular subtitle-1">
                    {{ feature.text }}
                  </h4>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <div class="svg-border-waves">
      <img src="~@/assets/img/wave2.svg" />
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      videoId: "i8IvvHJssWE",
      features: [
        {
          img: "https://i.imgur.com/0jl5HhQ.png",
          title: "Diseño Limpio",
          text: "Contamos con Desarrolladores y Diseñadores con un amplio conocimiento de las tecnologias utilizadas en el Diseño y desarrollo de una aplicacion web, esto hace de nuestro trabajo el mas limpio y mejor diseñado de la region.",
        },
        {
          img: "https://i.imgur.com/tCgt53q.png",
          title: "Seguridad de Datos",
          text: "Manejo y gestion de su base de datos, recomendandole la que mas se adapte al cliente, contando con desarrolladores expertos en las mismas para brindarle asi a nuestra clientela la certeza de que su informacion esta en buenas manos.",
        },
        {
          img: "https://i.imgur.com/LPSBCKL.png",
          title: "Código Abierto",
          text: "Todo nuestro codigo es abierto, esto quiere decir que una vez finalizada la etapa de desarrollo es puesta en produccion su aplicacion web, luego de eso usted es el dueño del codigo, transfiriendole la totalidad del mismo si asi lo desea.",
        },
      ],
    };
  },
  watch: {
    dialog(value) {
      if (!value) {
        this.pause();
      }
    },
  },
  methods: {
    
  },
};
</script>

<style lang="scss">
.compu{
  background-image: url("https://i.imgur.com/2WDjZ79.png");
  background-size:contain;
  height: 400px;
  margin-top: 40px;
  background-position: center;
  background-repeat: no-repeat;
}
.trans{
  color: rgba(255, 255, 255, 0.636);
}
  .tit{
    margin-bottom: 30px;
    font-size:60px;
    font-weight: bold;
  }
@media screen and (max-width: 960px){
  .tit{
    margin-bottom: 20px;
    font-size: 70px;
    font-weight: bold;
    text-align: center;
  }
  .tit2{
    font-size: 20px;
    text-align: center;

  }
}
@media screen and (max-width: 600px){
  .tit{
    margin-bottom: 20px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
  }
  .tit2{
    font-size: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 450px){
  .tit{
    margin-bottom: 20px;
    font-size: 45px;
    font-weight: bold;
    text-align: center;
  }
  .tit2{
    font-size: 10px;
    text-align: center;
  }
}
.svg-border-waves .v-image {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3rem;
  width: 100%;
  overflow: hidden;
}

#hero {
  z-index: 0;
}
.svg-border-waves img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin-bottom: -2px;
  z-index: -1;
}

.card {
  min-height: 300px;
  padding: 10px;
  transition: 0.5s ease-out;
}

.card .v-image {
  margin-bottom: 15px;
  transition: 0.75s;
}

.card h1 {
  margin-bottom: 10px;
}

.zoom-efect {
  transform: scale(1.1);
}

.up {
  transform: translateY(-20px);
  transition: 0.5s ease-out;
}

section {
  position: relative;
}
</style>
